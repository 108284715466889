import React, { Component } from "react"
import { Link } from "gatsby"
import ReactModal from 'react-modal';
import '../../node_modules/react-modal-video/css/modal-video.min.css';
import loadable from '@loadable/component'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import imgProfileIanTetley from "../images/gfx/testimonial-ian-tetley.jpg"
import imgProfileDaveHackshaw from "../images/gfx/testimonial-dave-hackshaw.jpg"

import imgClientApplegates from "../images/clients/applegates.png"
import imgClientAshwood from "../images/clients/ashwood-travel.png"
import imgClientCambridge from "../images/clients/cambridge-buses.png"
import imgClientLakeside from "../images/clients/lakeside.png"
import imgClientMarshalls from "../images/clients/marshalls.png"
import imgClientTetleys from "../images/clients/tetleys.png"

import imgHero from "../images/gfx/shuttleid-mobile-ticket-with-qr-code.jpg"
import imgMobileHero from "../images/gfx/mobile-hero.jpg"
import imgBenefitsFooter from "../images/gfx/benefits-footer-bg.png"
import imgBenefitsBg from "../images/gfx/benefits-end.png"
import imgBenefitTakeControl from "../images/gfx/benefit-full-take-control.png"
import imgBenefitAffordableHardware from "../images/gfx/benefit-full-affordable-hardware.png"
import imgBenefitStopTicketFraud from "../images/gfx/benefit-full-stop-ticket-fraud.png"
import imgBenefitSaveTime from "../images/gfx/benefit-full-save-time.png"
import imgBenefitIncreaseRevenue from "../images/gfx/benefit-full-increase-revenue.png"
import imgBenefitHappyCustomers from "../images/gfx/benefit-full-happy-customers.png"

import imgVideoPlaceholder from "../images/gfx/video-placeholder.jpg"

import imgIconClose from "../images/gfx/close.png"
import imgIconArrow from "../images/gfx/arrow.png"

import "./index.css";
import "./home.css";

import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"

const ModalVideo = loadable(() => import('react-modal-video'));

class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'sell',
      isVideoModalOpen: false,
      isModalOpen: false,
      modalText: 1
    };
  }

  handleVideoModalOpen = event => {
    event.preventDefault();
    this.setState({ isVideoModalOpen: true })
  }

  handleVideoModalClose = event => {
    this.setState({ isVideoModalOpen: false })
  }

  handleModalOpen(benefit) {
    this.setState({ isModalOpen: true, modalText: benefit });
  }

  handleModalClose(benefit) {
    this.setState({ isModalOpen: false });
  }

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  showTab(tab) {
    this.setState({
      activeTab: tab
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="The simple way to manage school and work transport. Simplify your payments, ticket distribution and scanning." description="Simplify your school, college and work transport with ShuttleID. Payments, ticket distribution, scanning and vehicle tracking using a simple but cost-effective approach." />
        <Nav />

        <ReactModal
          isOpen={this.state.isModalOpen}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => {
            this.setState({ isModalOpen: false });
          }}
          contentLabel=""
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              overflowY: 'auto',
              maxHeight: '100vh',
              display: 'flex',
              margin: 'auto'
            },
            content: {
              // position: 'absolute',
              // top: '50%',
              // left: '50%',
              // bottom: 'auto',
              // transform: 'translate(-50%, -50%)',
              border: 0,
              background: 'none',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: 5,
              minWidth: '310px',
              inset: 0,
              maxWidth: 640,
              margin: '0 auto 0 auto',
            }
          }}
        >
          {this.state.modalText === 1 && (
            <div className="benefit-modal">
              <div className="benefit-modal__head">
                <h4>1: Take control</h4>
                <div className="benefit-modal__btns">
                  <button disabled className="btn-prev btn-disabled" onClick={() => { this.handleModalOpen(5) }}><img src={imgIconArrow} alt="Previous" /></button>
                  <button className="btn-next" onClick={() => { this.handleModalOpen(2) }}><img src={imgIconArrow} alt="Next" /></button>
                  <button className="btn-close" onClick={() => { this.handleModalClose() }}><img src={imgIconClose} alt="Close" /></button>
                </div>
              </div>
              <div className="benefit-modal__body">
                <h3 className="benefit-modal__title">ShuttleID puts you in control</h3>
                <p><strong>Great technology without forfeiting control.</strong> Tools to help you better manage your school services without losing direct access to your customers or partners. More control not less.</p>
                <p><strong>No payment delays.</strong> You’ll receive payments direct from ticket buyers without extra delay.</p>
                <p><strong>Control over payments.</strong> Direct debit gives you full control over payments, including when payments automatically start and end, instant notification of failed payments and ability to retry at the click of a button.</p>
                <p><strong>Prevent bus overcrowding</strong> by setting limits on passenger numbers. ShuttleID automatically prevents overselling and enforces the validity of tickets.</p>
                <p><strong>Useful planning tools.</strong> Monitor route popularity and plan your resources effectively. Use our platform to analyse travel reports and grow your business.</p>
                <p><strong>Suspend or ban troublemakers with one click.</strong> It's impossible to get your physical pass back from an offender but now you don't need to.</p>
                <div className="benefit-modal__ctas">
                  <Link to="/case-study-keane-travel-reduce-admin-increase-revenue-school-travel/" className="btn btn-margin-right">See it in action</Link>
                  <Link to="/book-demo/" className="btn btn-margin-right">Book a demo</Link>
                </div>
              </div>
            </div>
          )}
          {this.state.modalText === 2 && (
            <div className="benefit-modal">
              <div className="benefit-modal__head">
                <h4>2: Affordable hardware</h4>
                <div className="benefit-modal__btns">
                  <button className="btn-prev" onClick={() => { this.handleModalOpen(1) }}><img src={imgIconArrow} alt="Previous" /></button>
                  <button className="btn-next" onClick={() => { this.handleModalOpen(3) }}><img src={imgIconArrow} alt="Next" /></button>
                  <button className="btn-close" onClick={() => { this.handleModalClose() }}><img src={imgIconClose} alt="Close" /></button>
                </div>
              </div>
              <div className="benefit-modal__body">
                <h3 className="benefit-modal__title">ShuttleID puts other systems in the shade</h3>
                <p><strong>Designed for home-to-school transport.</strong> Purpose built to offer maximum value on low frequency travel such as school services which only operate twice per day, 190 days per year.</p>
                <p><strong>Minimal outlay.</strong> With ticket scanning hardware available from just £40, we offer the best value ticket scanner on the market, making it a low-risk investment to roll out across your fleet.</p>
                <p><strong>No set up, reprogramming or licensing fees.</strong> Avoid hefty hardware costs normally associated with ticketing machines.</p>
                <p><strong>Portable. </strong> Reduce the amount of hardware required by sharing kit between multiple vehicles.</p>
                <p><strong>Multi-use. </strong> More bang for your buck by using hardware for other purposes, such as walkaround vehicle checks and other business related tasks.</p>
                <div className="benefit-modal__ctas">
                  <Link to="/case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless/" className="btn btn-margin-right">See it in action</Link>
                  <Link to="/book-demo/" className="btn btn-margin-right">Book a demo</Link>
                </div>
              </div>
            </div>
          )}
          {this.state.modalText === 3 && (
            <div className="benefit-modal">
              <div className="benefit-modal__head">
                <h4>3: Stop ticket fraud</h4>
                <div className="benefit-modal__btns">
                  <button className="btn-prev" onClick={() => { this.handleModalOpen(2) }}><img src={imgIconArrow} alt="Previous" /></button>
                  <button className="btn-next" onClick={() => { this.handleModalOpen(4) }}><img src={imgIconArrow} alt="Next" /></button>
                  <button className="btn-close" onClick={() => { this.handleModalClose() }}><img src={imgIconClose} alt="Close" /></button>
                </div>
              </div>
              <div className="benefit-modal__body">
                <h3 className="benefit-modal__title">ShuttleID – your ticket police</h3>
                <p><strong>No pass, no travel.</strong> Passes can be used on a phone and printed at home as a backup. Instant delivery and no delay on replacements means there is finally no excuse to not have a pass.</p>
                <p><strong>Fast, consistent and accurate inspection</strong>  that unburdens your drivers without impacting boarding throughput. Each ticket scan takes less than one second to complete, and only requires intervention from the driver when an issue is detected.</p>
                <p><strong>Identify expired, shared, forged tickets</strong>  as they are scanned. Scan log data is stored in the cloud so management has visibility of activity and reports across all services.</p>
                <p><strong>Suspend or ban troublemakers with one click.</strong> It’s impossible to get your physical pass back from an offender, but now you don’t need to.</p>
                <div className="benefit-modal__ctas">
                  <Link to="/case-study/" className="btn btn-margin-right">See it in action</Link>
                  <Link to="/book-demo/" className="btn btn-margin-right">Book a demo</Link>
                </div>
              </div>
            </div>
          )}
          {this.state.modalText === 4 && (
            <div className="benefit-modal">
              <div className="benefit-modal__head">
                <h4>4: Save time</h4>
                <div className="benefit-modal__btns">
                  <button className="btn-prev" onClick={() => { this.handleModalOpen(3) }}><img src={imgIconArrow} alt="Previous" /></button>
                  <button className="btn-next" onClick={() => { this.handleModalOpen(5) }}><img src={imgIconArrow} alt="Next" /></button>
                  <button className="btn-close" onClick={() => { this.handleModalClose() }}><img src={imgIconClose} alt="Close" /></button>
                </div>
              </div>
              <div className="benefit-modal__body">
                <h3 className="benefit-modal__title">ShuttleID cuts stress</h3>
                <p><strong>Avoid hours of admin.</strong> No more printing and posting passes or replacing lost or dog-eared tickets. It’s not uncommon to save hundreds of hours of admin annually!</p>
                {/* <p><strong>Driver friendly.</strong> ShuttleID is an intuitive system that requires minimal user knowledge. We’ve tested it on drivers, schools, parents, passengers and technophobes. Our first test pilot was over 80 years old and didn’t own a smartphone!</p> */}
                <p><strong>Same day set up.</strong> When you sign up, your account can be created and travel passes issued within 24 hours.</p>
                <p><strong>Reduce up to 80% of the job</strong> with automated renewals. Our renewal system minimises your admin of existing customers, leaving you a much smaller task of dealing with brand new customers.</p>
                <p><strong>Sell and issue your passes automatically.</strong>  Customers can purchase and receive tickets without any involvement of your staff, giving your team the freedom to focus on more important jobs.</p>
                <p><strong>No more floods of phone calls to deal with.</strong> SMS service alerts and live vehicle tracking means less fielding of phone calls from customers when a bus is running late.</p>
                <p><strong>Less time spent chasing failed payments.</strong>  No more guesswork each month figuring out who has or hasn’t paid. Our system instantly notifies you when a direct debit payment fails, automatically chases payment and allows you to retry payment at the click of a button.</p>
                <div className="benefit-modal__ctas">
                  <Link to="/case-study-cambridge-buses-help-local-authority-save-time-money/" className="btn btn-margin-right">See it in action</Link>
                  <Link to="/book-demo/" className="btn btn-margin-right">Book a demo</Link>
                </div>
              </div>
            </div>
          )}
          {this.state.modalText === 5 && (
            <div className="benefit-modal">
              <div className="benefit-modal__head">
                <h4>5: Increase revenue</h4>
                <div className="benefit-modal__btns">
                  <button className="btn-prev" onClick={() => { this.handleModalOpen(4) }}><img src={imgIconArrow} alt="Previous" /></button>
                  <button className="btn-next" onClick={() => { this.handleModalOpen(6) }}><img src={imgIconArrow} alt="Next" /></button>
                  <button className="btn-close" onClick={() => { this.handleModalClose() }}><img src={imgIconClose} alt="Close" /></button>
                </div>
              </div>
              <div className="benefit-modal__body">
                <h3 className="benefit-modal__title">ShuttleID builds turnover</h3>
                <p><strong>Tickets to fill every seat.</strong> Whatever type of passenger tickets your school partner needs, ShuttleID gives you the solution to approach new tenders with confidence. Choose annual, term, weekly, single, return, am/pm, flexi or custom passes: there are plenty of combinations to keep your buses full.</p>
                <p><strong>Generate leads</strong> through our waiting-list feature. Understanding passenger demand means you can organise your resources better and plan for new services.</p>
                <p><strong>Flexible payment terms to make travelling affordable. </strong> Large termly or annual payments may be unaffordable for many customers, which could mean you’re losing out to competitors or car sharing. With a system like ShuttleID, you can offer monthly instalments to increase patronage with minimal fuss.</p>
                <p><strong>Confidence for parents that your school bus is safe for their children.</strong>  SMS service alerts, live vehicle tracking, scan logs and a booking system that prevents overcrowding makes yours a better transport choice than a car or competitor.</p>
                <p><strong>Data that maximises revenue.</strong>  Real-time sales and scan log reports can help ensure only the right sized vehicle is sent out each day. Data helps inform decisions on whether to amalgamate services or safely oversell based on usage patterns.</p>
                <div className="benefit-modal__ctas">
                  <Link to="/case-study-keane-travel-reduce-admin-increase-revenue-school-travel/" className="btn btn-margin-right">See it in action</Link>
                  <Link to="/book-demo/" className="btn btn-margin-right">Book a demo</Link>
                </div>
              </div>
            </div>
          )}
          {this.state.modalText === 6 && (
            <div className="benefit-modal">
              <div className="benefit-modal__head">
                <h4>6: Happy customers</h4>
                <div className="benefit-modal__btns">
                  <button className="btn-prev" onClick={() => { this.handleModalOpen(5) }}><img src={imgIconArrow} alt="Previous" /></button>
                  <button disabled className="btn-next btn-disabled" onClick={() => { this.handleModalOpen(1) }}><img src={imgIconArrow} alt="Next" /></button>
                  <button className="btn-close" onClick={() => { this.handleModalClose() }}><img src={imgIconClose} alt="Close" /></button>
                </div>
              </div>
              <div className="benefit-modal__body">
                <h3 className="benefit-modal__title">ShuttleID makes life easier</h3>
                <p><strong>A cashless system</strong> that’s better for everyone. Not only does ShuttleID save customers the daily scrabble for fares, but drivers avoid the risks associated with carrying cash.</p>
                <p><strong>Convenient ticket purchasing for customers.</strong>  No longer restricted to your office hours or when your card machine is free, customers can make their purchase when it suits them.</p>
                <p><strong>Digital tickets</strong> are issued straight to phones. The QR code can be shared, scanned or printed 24/7. There’s no more stress of misplaced paper tickets when digital ones can be reissued in the blink of an eye.</p>
                <p><strong>Enhanced safeguarding.</strong> Live vehicle tracking and passenger data that shows exactly who boards your bus, as it happens – brilliant safeguarding tools for schools and an extra reassurance for parents.</p>
                <p><strong>Improve communication</strong> through SMS service alerts. Alert customers to critical messages quickly so they never miss important information from you.</p>
                <div className="benefit-modal__ctas">
                  <Link to="/case-study-plumpton-college-save-full-time-admin/" className="btn btn-margin-right">See it in action</Link>
                  <Link to="/book-demo/" className="btn btn-margin-right">Book a demo</Link>
                </div>
              </div>
            </div>
          )}

        </ReactModal>
        <div>
            <div>

              <div className="hero" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '60% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1>The simple way to manage school &amp; work transport</h1>
                <h2>The affordable, digital bus pass and ticketing system that keeps you in control</h2>
                <div className="hero__links">
                  <Link style={{ marginRight: 10 }} to="/packages/" className="btn">Packages</Link>
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              <div className="mobile-hero" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1>The simple way to manage school &amp; work transport</h1>
                <h2>The affordable, digital bus pass and ticketing system that keeps you in control</h2>
                <div className="hero__links">
                  <Link style={{ marginRight: 10 }} to="/packages/" className="btn">Packages</Link>
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              {/* <div style={{ background: '#9d874a', color: 'white' }}>
                <p style={{ margin: 0, padding: '5px' }}><strong>NEW:</strong> Check out our <Link style={{ color:'#fff', fontWeight: 'bold' }} to="/money-back-guarantee/">100-day money back guarantee</Link>!</p>
              </div> */}

              <div className="home-benefits" style={{ background: `url(${imgBenefitsFooter}), #fdfdfd`, backgroundSize: '100% auto, 100%', backgroundRepeat: 'no-repeat, repeat', backgroundPosition: 'center bottom, left' }}>
                <div className="home-benefits__intro" style={{ background: `url(${imgBenefitsBg}), #d8eaf3`, backgroundSize: 'auto 100% , 100%', backgroundRepeat: 'no-repeat, repeat', backgroundPosition: 'right top, left top' }}>
                  <h3>What is ShuttleID?</h3>
                  <p>A single system that offers <strong>mobile tickets, payments, reporting, service alerts, boarding notifications</strong> and <strong>live vehicle tracking</strong>.</p>
                  <p>We help modernise school, college and work transport with simple and affordable technology. Our technology is at the heart of over 8 million passenger trips each year.</p>
                  <Link style={{ marginRight: 10 }} to="/who-we-help/" className="btn btn-invert">Who We Help</Link>
                  <Link to="/case-studies/" className="btn btn-invert">Case studies</Link>
                </div>

                <div className="home-benefits__item">
                  <div className="home-benefits__item__head">
                    <img className="home-benefits__item__img" src={imgBenefitTakeControl} />
                    <p className="home-benefits__item__label">Take control</p>
                    <span className="home-benefits__item__hr"></span>
                  </div>
                  <p className="home-benefits__item__desc"><strong>Cut out the middleman.</strong> Seamless technology that connects you direct to your customers. Fast payments straight to you.</p>
                  <button className="btn" onClick={() => { this.handleModalOpen(1) }}>Find out more</button>
                </div>

                <div className="home-benefits__item">
                  <div className="home-benefits__item__head">
                    <img className="home-benefits__item__img" src={imgBenefitAffordableHardware} />
                    <p className="home-benefits__item__label">Affordable hardware</p>
                    <span className="home-benefits__item__hr"></span>
                  </div>
                  <p className="home-benefits__item__desc"><strong>Ticket-scanning hardware from just £50 per unit.</strong> Forget what you thought you knew about ticketing machines.</p>
                  <button className="btn" onClick={() => { this.handleModalOpen(2) }}>Find out more</button>
                </div>

                <div className="home-benefits__item">
                  <div className="home-benefits__item__head">
                    <img className="home-benefits__item__img" src={imgBenefitStopTicketFraud} />
                    <p className="home-benefits__item__label">Stop ticket fraud</p>
                    <span className="home-benefits__item__hr"></span>
                  </div>
                  <p className="home-benefits__item__desc"><strong>Confidence passes are checked consistently - </strong> using simple technology, with scan logs available to management.</p>
                  <button className="btn" onClick={() => { this.handleModalOpen(3) }}>Find out more</button>
                </div>

                <div className="home-benefits__item">
                  <div className="home-benefits__item__head">
                    <img className="home-benefits__item__img" src={imgBenefitSaveTime} />
                    <p className="home-benefits__item__label">Save time</p>
                    <span className="home-benefits__item__hr"></span>
                  </div>
                  <p className="home-benefits__item__desc"><strong>Skip the mad rush</strong> of printing and posting passes, chasing payments and handling lost passes so you can focus on what really matters.</p>
                  <button className="btn" onClick={() => { this.handleModalOpen(4) }}>Find out more</button>
                </div>

                <div className="home-benefits__item">
                  <div className="home-benefits__item__head">
                    <img className="home-benefits__item__img" src={imgBenefitIncreaseRevenue} />
                    <p className="home-benefits__item__label">Increase revenue</p>
                    <span className="home-benefits__item__hr"></span>
                  </div>
                  <p className="home-benefits__item__desc"><strong>Features to grow your business.</strong>  Flexible ticketing and waiting lists help you fill every bus seat and win more tenders.</p>
                  <button className="btn" onClick={() => { this.handleModalOpen(5) }}>Find out more</button>
                </div>

                <div className="home-benefits__item">
                  <div className="home-benefits__item__head">
                    <img className="home-benefits__item__img" src={imgBenefitHappyCustomers} />
                    <p className="home-benefits__item__label">Happy customers</p>
                    <span className="home-benefits__item__hr"></span>
                  </div>
                  <p className="home-benefits__item__desc"><strong>Convenient, 24/7 ticket purchasing for busy customers.</strong> No more pass replacement fees or hunting for correct change. Service alerts and <Link to="/school-bus-tracking-system-app/">live tracking</Link> keep customers informed.</p>
                  <button className="btn" onClick={() => { this.handleModalOpen(6) }}>Find out more</button>
                </div>


              </div>

              <div className='home-how-it-works'>


                <h3>How it all works</h3>

                <p className="home-how-it-works__intro" >Watch our explanatory video below for an insight on how our system can help transform your school, college or work services.</p>
                <ModalVideo channel='vimeo' autopause={false} autoplay={true} isOpen={this.state.isVideoModalOpen} videoId='557493546' onClose={() => this.setState({isVideoModalOpen: false})} />
                <Link to="#" onClick={ this.handleVideoModalOpen }>
                  <img className="home-video-placeholder" src={imgVideoPlaceholder} />
                </Link>

                <div className="home-how-it-works__btns">
                  <Link to="/packages/" className="btn btn-invert">Packages</Link>
                  <Link to="/contact/" className="btn btn-invert">Contact</Link>
                </div>
              </div>

              <div className='testimonials'>
                <h3>What our customers are saying</h3>
                <p className="testimonials__intro" style={{ marginBottom: 20 }}>We take great pride in the personal service we offer to transport providers. Here's a small selection of the great feedback clients have shared with us.</p>

                <div style={{ color: '#776900', marginTop: 40, width: '100%', flex: 'none' }} data-aos="zoom-in" data-aos-duration="600">
                  <div className='testimonial-parent'>
                    <div className='testimonial' data-aos="fade-right" data-aos-duration="400">
                      <div className='testimonial__profile'>
                        <img className="testimonial__profile__img" src={imgProfileIanTetley} alt="Ian Tetley" />
                        <div className="testimonial__profile__author">
                          <p><strong>Ian Tetley</strong><br />Managing Director<br />Tetley's Coaches</p>
                        </div>
                      </div>
                      <div className='testimonial__quote'>
                        <p>"There's less operator involvement in the actual process. It just goes on in the background. We've got full observation and transparency, so we know the job's being done. The fraud has disappeared. I've also got the comfort of knowing that the revenue we do generate is in our bank fairly quickly."</p>
                      </div>
                    </div>

                    <div className='testimonial' data-aos="fade-left" data-aos-duration="400">
                      <div className='testimonial__profile'>
                        <img className="testimonial__profile__img" src={imgProfileDaveHackshaw} alt="Dave Hackshaw" />
                        <div className="testimonial__profile__author">
                          <p><strong>Dave Hackshaw</strong><br />Commercial Director<br />Cambridge Buses</p>
                        </div>
                      </div>
                      <div className='testimonial__quote'>
                        <p>"We always had an ambition to have some form of bus pass checker. Up until now they've either been super complex or just not out there at all. The introduction has been really smooth and really quick. Just in the first two weeks, you can see that everyone has got it."</p>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='testimonials__hr'></div>

                <h3>Trusted</h3>
                <p>by over 100 transport providers across the UK & ROI including:</p>
                <div className="testimonials_client-list">
                  <div>
                    <img src={imgClientTetleys} alt="" />
                  </div>
                  <div><img src={imgClientApplegates} alt="" /></div>

                  <div><img src={imgClientLakeside} alt="" /></div>
                  <div><img src={imgClientAshwood} alt="" /></div>
                  <div><img src={imgClientMarshalls} alt="" /></div>

                  <div><img src={imgClientCambridge} alt="" /></div>
                </div>
                <p style={{ textAlign: 'center', paddingBottom: 50, margin: 0 }}>
                  <Link to="/case-studies/" className="btn">Read case studies</Link>
                </p>
              </div>

              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default IndexPage
